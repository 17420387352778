import React from "react"
import { graphql } from "gatsby"
import { faRss } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/Layout"
import HomeSectionInfinite from "../../components/HomeSectionInfinite"
import Breadcrumbs from "../../components/Breadcrumbs"
import SEO from "../../components/SEO"

const Feed = ({ data }) => {
  const cards = data.allCardsPt.edges.sort(
    (a, b) => -a.node.createdAt.localeCompare(b.node.createdAt)
  )

  return (
    <Layout>
      <SEO
        title="Feed"
        canonical={`${process.env.GATSBY_HOST_URL}/pt/cards`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/en/cards`}
      />

      <div
        className="main-content feed-main-content"
        style={{ marginTop: 117 }}
      >
        <Breadcrumbs currentPage="Feed" style={{ marginTop: 0 }} />

        <HomeSectionInfinite
          name="Feed"
          cards={cards}
          icon={faRss}
          style={{ marginTop: 25 }}
          customClass="home-section-card-list"
        />
      </div>
    </Layout>
  )
}

export default Feed

export const query = graphql`
  query {
    allCardsPt {
      edges {
        node {
          name
          slug
          imageUrl
          createdAt
          type
          topic {
            name
            slug
            color
          }
          meta {
            author
          }
          tags {
            name
          }
        }
      }
    }
  }
`
